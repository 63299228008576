import BookingService from '../../../Services/BookingService';

/**
   * Archive order
   * @param {object} order
   */
export default (
  {
    setIsLoading, fetchOrders, setError,
  },
) => async (order) => {
  setIsLoading(true);
  try {
    await BookingService.postBooking({
      ...order,
      is_archived: true,
      send_notification_to_customer: false,
    });
    setIsLoading(false);
    fetchOrders();
  } catch (err) {
    setError(err.response.data.message);
  } finally {
    setIsLoading(false);
  }
};

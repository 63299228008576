/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  Button, ButtonGroup, Dropdown, Form, OverlayTrigger, Table,
  Tooltip,
} from 'react-bootstrap';
import OrderLineAdjustmentsModal from './OrderLineAdjustmentsModal';
import OperatorInputModal from './OperatorInputModal';
import OrderInputModal from './OrderInputModal';
import OrderDetailsModal from './OrderDetailsModal';
import TripConfirmationModal from './TripConfirmationModal';
import { formatDate, formatTime } from '../../../Utils/dateFormat';
import OrderWarnings from './OrderWarnings';
import SortByColumn from './SortByColumn';
import DriverInputModal from './DriverInputModal';
import thousandSeparator from '../../../Utils/numberFormat';
import PickupGuideInputModal from './PickupGuideInputModal';

function OrdersTable({
  columns = {
    warnings: false,
    partner: true,
    driver: false,
    partner_booking_reference: false,
    customer_name: true,
    operator: true,
    operator_price: false,
    phone_number: true,
    email_address: false,
    transfer_date: true,
    from: true,
    pax: false,
    to: true,
    price: true,
    status: true,
    category: false,
    actions: {
      orders: true,
    },
  },
  orders,
  setFilters,
  markAsCompleted,
  setPage,
  page,
  totalPages,
  archiveOrder,
  isLoading,
  fetchOrders,
  orderInputModal,
  operatorInputModal,
  orderLineAdjustmentsModal,
  orderDetailsModal,
  driverInputModal,
  tripConfirmationModal,
  pickupGuideModal,
  sortFn,
}) {
  return (
    <>
      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            {columns.warnings && <th />}
            {columns.partner && <th>Partner</th>}
            {columns.partner_booking_reference && <th>Partner #</th>}
            {columns.operator && <th>Operator</th>}
            {columns.operator_price && <th>Operator Price</th>}
            {columns.driver && <th>Driver</th>}
            {columns.customer_name && <th>Passenger name</th>}
            {columns.category && <th>Category</th>}
            {columns.pax && <th>Pax</th>}
            {columns.flight_number && <th>Flight number</th>}
            {columns.email_address && <th>Email address</th>}
            {columns.phone_number && <th>Phone Number</th>}
            {columns.transfer_date && (
            <th style={{ whiteSpace: 'nowrap' }}>
              Transfer date
              <SortByColumn
                column="date_datetime"
                sortFn={sortFn}
              />
            </th>
            )}
            {columns.from && <th>From</th>}
            {columns.to && <th>To</th>}
            {columns.price && <th>Price</th>}
            {columns.status && <th>Status</th>}
            <th />
          </tr>
        </thead>
        {!isLoading && (
        <tbody>
          {orders.map((order) => (
            <tr key={order.booking_reference}>
              {columns.warnings && (
              <td>
                <OrderWarnings
                  assignOperator={() => operatorInputModal.show(order)}
                  assignDriver={() => driverInputModal.show(order)}
                  markAsCompleted={markAsCompleted}
                  assignPickupGuide={() => pickupGuideModal.show(order)}
                  order={order}
                />
              </td>
              )}
              {columns.partner && <td>{order.partner?.name}</td>}
              {columns.partner_booking_reference && <td>{order.partner_booking_reference}</td>}
              {columns.operator && (
              <td>
                {order.operator?.name}
                {order.operator && (
                <>
                  { ' ' }
                  {!order.operator_update_acknowledged && (
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip>Operator has not acknowledged</Tooltip>
                          )}
                  >
                    <span className="fa fa-warning text-danger" />
                  </OverlayTrigger>
                  )}
                </>
                )}
              </td>
              )}
              {columns.operator_price && (
              <td>
                {thousandSeparator(order.operator_price)}
                {' '}
                {order.operator_currency}
              </td>
              )}
              {columns.driver && (
              <td>
                {order.driver?.full_name}
                {order.driver?.phone_number && (<br />)}
                {order.driver?.phone_number}
                {order.car && (
                  <>
                    <br />
                    {`${order.car.type}`}
                    {order.car.color && `(${order.car.color})`}
                    <br />
                    {order.car.plate_number}
                  </>
                ) }
              </td>
              )}
              {columns.customer_name && <td>{order.full_name}</td>}
              {columns.category && <td>{order.category.name}</td>}
              {columns.pax && <td>{order.passengers.amount}</td>}
              {columns.flight_number && <td>{order.flight_number}</td>}
              {columns.email_address && <td><btn onClick={() => setFilters({ email_address: order.email_address })} className="link-primary" style={{ cursor: 'pointer' }}>{order.email_address}</btn></td>}
              {columns.phone_number && <td>{order.phone_number}</td>}
              {columns.transfer_date && (
              <td>
                {order.return_date && <span className="badge text-bg-primary">Return Journey</span>}
                {order.date_datetime ? (
                  <>
                    {formatDate(order.date_datetime)}
                    <br />
                    {formatTime(order.date_datetime)}
                  </>
                ) : 'INCORRECT_DATE'}
                { ' ' }
                {order.return_date_datetime && (
                <>
                  <br />
                  <span className="fa fa-right-left" />
                  <br />
                  { ' ' }
                  {formatDate(order.return_date_datetime)}
                  <br />
                  {formatTime(order.return_date_datetime)}
                </>
                )}
              </td>
              )}
              {columns.from && <td>{order.destination_from.address}</td>}
              {columns.to && <td>{order.destination_to.address}</td>}
              {columns.price && (
              <td>
                {thousandSeparator(order.total_price) ?? thousandSeparator(order.price)}
                {' '}
                {order.currency || 'EUR'}
              </td>
              )}
              {columns.status && (
              <td>
                <span className={`badge ${
                  order.status === 'Confirmed' ? 'text-bg-primary'
                    : order.status === 'Completed' ? 'text-bg-success'
                      : order.status === 'Request' ? 'text-bg-warning'
                        : order.status === 'Cancelled' ? 'text-bg-danger' : ''
                }`}
                >
                  {order.status}
                </span>
              </td>
              )}
              {columns.actions.orders && (
              <td>
                <Dropdown as={ButtonGroup}>
                  <ButtonGroup>
                    <Button
                      variant="primary"
                      onClick={() => orderDetailsModal.show(order)}
                    >
                      Details
                    </Button>
                    <Button
                      variant="outline-primary"
                      aria-label="Order Line Adjustments"
                      onClick={() => {
                        orderLineAdjustmentsModal.show(order);
                      }}
                    >
                      <i className="fa fa-euro-sign" />
                    </Button>
                  </ButtonGroup>

                  <Dropdown.Toggle variant="outline-primary" split id="dropdown-split-basic" />

                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={order.status === 'Completed'}
                      onClick={() => {
                        orderInputModal.show(order);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={!order.operator_id || order.status === 'Completed'}
                      onClick={() => {
                        markAsCompleted(order);
                      }}
                    >
                      Mark as Completed
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={!order.partner?.driver_portal_enabled}
                      onClick={() => {
                        pickupGuideModal.show(order);
                      }}
                    >
                      Assign Pickup Guide
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={order.status === 'Completed'}
                      onClick={() => {
                        operatorInputModal.show(order);
                      }}
                    >
                      Assign Operator
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={order.status !== 'Confirmed' || !order.operator_id}
                      onClick={() => {
                        driverInputModal.show(order);
                      }}
                    >
                      Assign Driver & Car
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        tripConfirmationModal.show(order);
                      }}
                    >
                      Generate Trip Confirmation
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        archiveOrder(order);
                      }}
                    >
                      Archive
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              )}
            </tr>
          ))}
        </tbody>
        ) }
        { isLoading && (
        <tbody>
          {[...Array(3)].map((_) => (
            <tr key={_}>
              {Object.entries(columns).map(([key, value]) => (value === true ? (
                <td key={key} className="placeholder-glow">
                  <span className="w-100 placeholder" />
                </td>
              ) : null))}
              <td className="placeholder-glow">
                <span className="w-100 placeholder" />
              </td>
            </tr>
          ))}
        </tbody>
        ) }
      </Table>

      <div className="d-block mt-2">
        <ButtonGroup>
          <Button
            onClick={() => setPage(Math.max(page - 1, 1))}
            disabled={page === 1}
            variant="secondary"
            size="sm"
          >
            Previous
          </Button>
          <Button
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages}
            variant="secondary"
            size="sm"
          >
            Next
          </Button>
        </ButtonGroup>

        {/* Select field for direct page navigation */}
        <Form.Select
          aria-label="Select page"
          onChange={(e) => setPage(Number(e.target.value))}
          value={page}
          size="sm"
          style={{ width: 'auto', display: 'inline-block' }}
          className="ms-2"
        >
          {Array.from({ length: totalPages }, (key, i) => (
            <option key={i + 1} value={i + 1}>
              Page
              {' '}
              {i + 1}
            </option>
          ))}
        </Form.Select>

        <p>
          Page
          {' '}
          {page}
          {' '}
          of
          {' '}
          {totalPages}
        </p>
      </div>

      { isLoading && <div>Loading...</div> }

      {orderDetailsModal.isVisible && (
      <OrderDetailsModal
        order={orderDetailsModal.selectedObject}
        show={orderDetailsModal.isVisible}
        handleClose={orderDetailsModal.hide}
      />
      ) }

      {orderInputModal.isVisible && (
      <OrderInputModal
        originalOrder={orderInputModal.selectedObject}
        show={orderInputModal.isVisible}
        fetchOrders={fetchOrders}
        handleClose={orderInputModal.hide}
      />
      ) }

      { operatorInputModal.isVisible && (
      <OperatorInputModal
        originalOrder={operatorInputModal.selectedObject}
        show={operatorInputModal.isVisible}
        handleClose={operatorInputModal.hide}
        fetchOrders={fetchOrders}
      />
      ) }

      { driverInputModal.isVisible && (
      <DriverInputModal
        originalOrder={driverInputModal.selectedObject}
        show={driverInputModal.isVisible}
        handleClose={driverInputModal.hide}
        fetchOrders={fetchOrders}
      />
      ) }

      { orderLineAdjustmentsModal.isVisible && (
      <OrderLineAdjustmentsModal
        originalOrder={orderLineAdjustmentsModal.selectedObject}
        show={orderLineAdjustmentsModal.isVisible}
        handleClose={orderLineAdjustmentsModal.hide}
        fetchOrders={fetchOrders}
      />
      ) }

      { tripConfirmationModal.isVisible && (
      <TripConfirmationModal
        order={tripConfirmationModal.selectedObject}
        show={tripConfirmationModal.isVisible}
        handleClose={tripConfirmationModal.hide}
      />
      ) }

      { pickupGuideModal.isVisible && (
      <PickupGuideInputModal
        originalOrder={pickupGuideModal.selectedObject}
        show={pickupGuideModal.isVisible}
        handleClose={pickupGuideModal.hide}
        fetchOrders={fetchOrders}
      />
      ) }
    </>
  );
}

export default OrdersTable;

/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import _ from 'lodash';
import { post } from '../../../Services/API';
import Error from '../../../Components/Error';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import setStateFromInput from '../../../Utils/setStateFromInput';

function OperatorInputModal({
  originalOperator = {}, show = false, handleClose, fetchOperators,
}) {
  const defaultOperator = {
    active: 1,
    billing: {},
  };

  const [operator, setOperator] = useState(_.defaults(originalOperator, defaultOperator));
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await post('/operators', { ...operator, active: Number(operator.active) });
      fetchOperators();
      handleClose();
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle input change
   * @param {object} e
   * @returns void
   */
  const handleChange = (e) => {
    setStateFromInput(e, setOperator, operator);
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>
          { operator.Id && (
          <>
            Edit operator
            { ' ' }
            { operator.name }
          </>
          ) }
          { !operator.Id && (
          <>
            New operator
          </>
          ) }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <Form>
          <Form.Group className="mb-3 col-12" controlId="name">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="text"
              value={operator.name}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="name" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="settlement_currency">
            <Form.Label>Settlement Currency *</Form.Label>
            <Form.Select
              value={operator.settlement_currency}
             // disabled={operator.Id}
              onChange={handleChange}
            >
              <option value="">Choose settlement currency</option>
              <option>EUR</option>
              <option>HUF</option>
            </Form.Select>
            <FormControlFeedback errors={validationErrors} field="settlement_currency" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="website">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              value={operator.website}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="website" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              value={operator.email}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="email" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="phone_number">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              value={operator.phone_number}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="phone_number" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.name">
            <Form.Label>Billing Name</Form.Label>
            <Form.Control
              type="text"
              value={operator.billing?.name}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.name" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.address">
            <Form.Label>Billing Address</Form.Label>
            <Form.Control
              type="text"
              value={operator.billing?.address}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.address" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.city">
            <Form.Label>Billing City</Form.Label>
            <Form.Control
              type="text"
              value={operator.billing?.city}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.city" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.postcode">
            <Form.Label>Billing Postcode</Form.Label>
            <Form.Control
              type="text"
              value={operator.billing?.postcode}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.postcode" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="billing.country">
            <Form.Label>Billing Country</Form.Label>
            <Form.Control
              type="text"
              value={operator.billing?.country}
              onChange={handleChange}
            />
            <FormControlFeedback errors={validationErrors} field="billing.country" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="active">
            <Form.Label>Active</Form.Label>
            <Form.Select
              value={operator.active}
              onChange={handleChange}
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </Form.Select>
          </Form.Group>
        </Form>
        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit Operator
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default OperatorInputModal;

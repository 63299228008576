import React from 'react';
import { NavLink } from 'react-router-dom';

function Home({ session }) {
  return (
    <>
      <h2>
        Welcome,
        {' '}
        { session.givenName }
      </h2>
      <p>DEVOLOGY.IO Order Management System</p>

      <div className="row mt-5">

        <div className="col-12 mb-3">
          <h2>Group: Pickup Hungary</h2>
        </div>

        <NavLink to="/trips" className="col-sm-6 col-12 col-md-3">
          <div className="text-bg-primary card ratio ratio-1x1">
            <div className="card-body">
              <h3 className="card-title">Trips</h3>
              Trips Management
            </div>
          </div>
        </NavLink>

        <NavLink to="/orders" className="col-sm-6 col-12 col-md-3">
          <div className="text-bg-primary card ratio ratio-1x1">
            <div className="card-body">
              <h3 className="card-title">Orders</h3>
              View and Manage orders
            </div>
          </div>
        </NavLink>

        <NavLink to="/stats" className="col-12 col-sm-6 col-md-3">
          <div className="text-bg-primary card ratio ratio-1x1">
            <div className="card-body">
              <h3 className="card-title">Statistics</h3>
              View Statistics
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}

export default Home;

import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// ButtonWithTooltip component definition
function ButtonWithTooltip({
  variant, faClassName, message, onClick,
}) {
  // Function to render the tooltip
  const renderTooltip = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      overlay={renderTooltip}
    >
      <Button variant={variant} size="sm" onClick={onClick}>
        <span className={`fa ${faClassName}`} />
      </Button>
    </OverlayTrigger>
  );
}

export default ButtonWithTooltip;

import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import _ from 'lodash';
import Error from '../../Components/Error';
import OperatorInputModal from './Components/OperatorInputModal';
import useModalState from '../../Hooks/useModalState';
import OperatorService from '../../Services/OperatorService';

function Operators() {
  const [operators, setOperators] = useState([]);
  const [error, setError] = useState('');

  // New or existing order change
  const operatorInputModal = useModalState();

  const fetchOperators = async () => {
    try {
      const fetchedOperators = await OperatorService.fetchOperators();
      setOperators(fetchedOperators?.data?.data?.operators);
    } catch (err) {
      setError(error);
    }
  };

  const debouncedFetchOperators = _.debounce(() => {
    fetchOperators();
  }, 500);

  useEffect(() => {
    debouncedFetchOperators();
    return () => debouncedFetchOperators.cancel();
  }, []);

  return (
    <>
      <Error error={error} setError={setError} />

      <Button onClick={() => operatorInputModal.show({})}>
        Add New Operator
      </Button>

      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Settlement Currency</th>
            <th>E-mail address</th>
            <th>Website</th>
            <th>Phone number</th>
            <th>Active</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {operators.map((operator) => (
            <tr key={operator.id}>
              <td>{operator.name}</td>
              <td>{operator.settlement_currency}</td>
              <td>{operator.email}</td>
              <td>{operator.website}</td>
              <td>{operator.phone_number}</td>
              <td>{operator.active ? 'Yes' : 'No'}</td>
              <td>
                <Button
                  size="sm"
                  onClick={() => operatorInputModal.show(operator)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {operatorInputModal.isVisible && (
      <OperatorInputModal
        originalOperator={operatorInputModal.selectedObject}
        show={operatorInputModal.isVisible}
        fetchOperators={fetchOperators}
        handleClose={operatorInputModal.hide}
      />
      ) }
    </>
  );
}

export default Operators;

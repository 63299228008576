/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Table, Form, Button,
  Modal,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import dayjs from 'dayjs';
import { Typeahead } from 'react-bootstrap-typeahead';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import format from '../../../Utils/dateFormat';
import setStateFromInput from '../../../Utils/setStateFromInput';

function OrderInputForm({
  originalOrder,
  validationErrors,
  partners,
  setOriginalOrder,
  paymentTypes,
  orderStatuses,
  setIsPreviewMode,
}) {
  const [order, setOrder] = useState(originalOrder);
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [decoratedPartners, setDecoratedPartners] = useState([]);
  const [datetime, setDatetime] = useState(order.date_datetime
    ? format(order.date_datetime) : null);
  const [returnDatetime, setReturnDatetime] = useState(order.return_date_datetime
    ? format(order.return_date_datetime) : null);

  const decoratePartnerOption = (option) => {
    if (!option) return null;

    return {
      id: option.Id,
      label: `${option.name}`,
    };
  };

  const [selectedPartner, setSelectedPartner] = useState([]);

  const handleSubmit = () => {
    order.date_datetime = datetime
      ? dayjs(datetime).utc(true).toISOString() : undefined;
    order.return_date_datetime = returnDatetime
      ? dayjs(returnDatetime).utc(true).toISOString() : null;

    setOriginalOrder(order);
    setIsPreviewMode(true);
  };

  const recalculatePassengers = () => {
    const amount = Number(order.passengers.adults)
        + Number(order.passengers.infant_carrier)
        + Number(order.passengers.booster_seat)
        + Number(order.passengers.convertible_seat);

    setTotalPassengers(amount);

    if (amount !== order.passengers.amount) {
      setOrder((prevState) => ({ ...prevState, passengers: { ...prevState.passengers, amount } }));
    }
  };

  /**
   * Handle input change
   * @param {object} e
   * @returns void
   */
  const handleChange = (e) => {
    setStateFromInput(e, setOrder);
  };

  const setBillingDetailsFromPartner = (partnerId) => {
    const partnerBillingDetails = partners.find((partner) => partner.Id === partnerId);

    if (partnerBillingDetails) {
      const billingDetails = {};

      billingDetails.name = partnerBillingDetails.billing?.name || partnerBillingDetails.name;
      billingDetails.address = partnerBillingDetails.billing?.address || '';
      billingDetails.country = partnerBillingDetails.billing?.country || '';
      billingDetails.postcode = partnerBillingDetails.billing?.postcode || '';
      billingDetails.city = partnerBillingDetails.billing?.city || '';

      setOrder((prevState) => (
        { ...prevState, billing: { ...billingDetails } }
      ));
    }
  };

  const recalculateMaxCapacityAndLuggage = () => {
    if (order._id) return;

    setOrder((prevState) => (
      {
        ...prevState,
        category: {
          ...prevState.category,
          max_luggage: totalPassengers,
          capacity: totalPassengers,
        },
      }
    ));
  };

  const updatePartner = () => {
    if (order.partner_id && partners.length !== 0) {
      const decoratedPartner = decoratePartnerOption(
        partners.find((partner) => partner.Id === order.partner_id),
      );

      if (decoratedPartner) {
        setSelectedPartner([decoratedPartner]);
      }
    }
  };

  useEffect(() => {
    setTimeout(recalculatePassengers);

    if (order.partner_id
            && !(
              order.billing.name
              || order.billing.address
              || order.billing.country
              || order.billing.postcode
              || order.billing.ciy)) {
      setTimeout(() => setBillingDetailsFromPartner(order.partner_id));
    }

    updatePartner();
  }, [order]);

  useEffect(() => {
    const partnersTypeAhead = [];

    partners.forEach((partnerRaw) => {
      partnersTypeAhead.push(
        decoratePartnerOption(partnerRaw),
      );
    });

    setDecoratedPartners(partnersTypeAhead);
    updatePartner();
  }, [partners]);

  useEffect(() => {
    let partnerId;

    if (Array.isArray(selectedPartner) && selectedPartner.length === 1) {
      partnerId = selectedPartner[0].id;

      if (order.partner_id !== partnerId) {
        setOrder((prev) => ({
          ...prev,
          partner_id: partnerId,
        }));
      }
    }
  }, [selectedPartner]);

  return (
    <>
      <Table striped responsive>
        {/* Partner Information */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Partner Information</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Partner
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="partner_id">
              <Typeahead
                placeholder="Select a partner"
                id="partner_id"
                onChange={setSelectedPartner}
                options={decoratedPartners}
                selected={selectedPartner}
              />
              {/* <Form.Select
                className="bg-warning-subtle"
                value={order.partner_id}
                onChange={handleChange}
              >
                <option value="">Select a partner</option>
                {partners.map((partner) => (
                  <option key={partner.Id} value={partner.Id}>{partner.name}</option>
                ))}
              </Form.Select> */}
              <FormControlFeedback errors={validationErrors} field="partner_id" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Partner Booking Reference</td>
          <td>
            <Form.Group controlId="partner_booking_reference">
              <Form.Control
                type="text"
                value={order.partner_booking_reference}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="partner_booking_reference" />
            </Form.Group>
          </td>
        </tr>

        {/* Passenger Information */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Passenger Information</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Full Name
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="full_name">
              <Form.Control
                className="bg-warning-subtle"
                type="text"
                value={order.full_name}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="full_name" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Email Address
          </td>
          <td>
            <Form.Group controlId="email_address">
              <Form.Control
                type="email"
                value={order.email_address}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="email_address" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Phone Number
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="phone_number">
              <Form.Control
                className="bg-warning-subtle"
                type="text"
                value={order.phone_number}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="phone_number" />
            </Form.Group>
          </td>
        </tr>

        {/* Travel Information */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Travel Information</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Date
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="date_datetime">
              <Datetime
                inputProps={{ className: 'bg-warning-subtle form-control' }}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                value={datetime}
                onChange={(value) => setDatetime(value)}
              />
              <FormControlFeedback errors={validationErrors} field="date_datetime" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Return Date</td>
          <td>
            <Form.Group controlId="return_date_datetime">
              <Datetime
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                value={returnDatetime}
                onChange={(value) => setReturnDatetime(value)}
              />
              <FormControlFeedback errors={validationErrors} field="return_date_datetime" />
            </Form.Group>
          </td>
        </tr>

        {/* Destination From */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Address</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            From
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="destination_from.address">
              <Form.Control
                className="bg-warning-subtle"
                type="text"
                value={order.destination_from.address}
                onChange={(e) => {
                  const [parentKey, childKey] = e.target.id.split('.');
                  setOrder({
                    ...order,
                    [parentKey]: {
                      ...order[parentKey],
                      [childKey]: e.target.value,
                    },
                  });
                }}
              />
              <FormControlFeedback errors={validationErrors} field="destination_from.address" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            To
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="destination_to.address">
              <Form.Control
                className="bg-warning-subtle"
                type="text"
                value={order.destination_to.address}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="destination_to.address" />
            </Form.Group>
          </td>
        </tr>

        {/* Flight and Comments */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Flight and Comments</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">Flight Number</td>
          <td>
            <Form.Group controlId="flight_number">
              <Form.Control
                type="text"
                value={order.flight_number}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="flight_number" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Comments</td>
          <td>
            <Form.Group controlId="comments">
              <Form.Control
                as="textarea"
                value={order.comments}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="comments" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Admin Comments</td>
          <td>
            <Form.Group controlId="admin_comments">
              <Form.Control
                as="textarea"
                value={order.admin_comments}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="admin_comments" />
            </Form.Group>
          </td>
        </tr>

        {/* Passengers */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Passengers</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Total Amount
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="passengers.amount">
              <Form.Control
                className="bg-warning-subtle"
                type="number"
                disabled
                value={totalPassengers}
              />
              <FormControlFeedback errors={validationErrors} field="passengers.amount" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Adults
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="passengers.adults">
              <Form.Control
                className="bg-warning-subtle"
                type="number"
                value={Number(order.passengers.adults)}
                onBlur={recalculateMaxCapacityAndLuggage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="passengers.adults" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Convertible Seat</td>
          <td>
            <Form.Group controlId="passengers.convertible_seat">
              <Form.Control
                type="number"
                value={Number(order.passengers.convertible_seat)}
                onBlur={recalculateMaxCapacityAndLuggage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="passengers.convertible_seat" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Booster Seat</td>
          <td>
            <Form.Group controlId="passengers.booster_seat">
              <Form.Control
                type="number"
                value={Number(order.passengers.booster_seat)}
                onBlur={recalculateMaxCapacityAndLuggage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="passengers.booster_seat" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Infant Carrier</td>
          <td>
            <Form.Group controlId="passengers.infant_carrier">
              <Form.Control
                type="number"
                value={Number(order.passengers.infant_carrier)}
                onBlur={recalculateMaxCapacityAndLuggage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="passengers.infant_carrier" />
            </Form.Group>
          </td>
        </tr>

        {/* Order Details */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Order Details</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Order Status
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="status">
              <Form.Select
                className="bg-warning-subtle"
                value={order.status}
                onChange={handleChange}
              >
                <option value="">Select order status</option>
                {orderStatuses.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Select>
              <FormControlFeedback errors={validationErrors} field="status" />
            </Form.Group>
          </td>
        </tr>
        {order.status === 'Cancelled' && (
          <>
            <tr>
              <td className="bg-body-secondary">
                Operator Cancellation Compensation
                <i className="fa fa-asterisk text-danger bg-body-secondary" />
              </td>
              <td>
                <Form.Group controlId="operator_cancellation_price">
                  <Form.Control
                    type="number"
                    value={order.operator_cancellation_price}
                    onChange={handleChange}
                  />
                  <FormControlFeedback errors={validationErrors} field="operator_cancellation_price" />
                </Form.Group>
                <Form.Group controlId="operator_cancellation_currency">
                  <Form.Select
                    value={order.operator_cancellation_currency}
                    onChange={handleChange}
                  >
                    <option value="">Select currency</option>
                    <option>EUR</option>
                    <option>HUF</option>
                  </Form.Select>
                  <FormControlFeedback errors={validationErrors} field="operator_cancellation_currency" />
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td className="bg-body-secondary">
                Partner Cancellation Compensation
                <i className="fa fa-asterisk text-danger bg-body-secondary" />
              </td>
              <td>
                <Form.Group controlId="partner_cancellation_price">
                  <Form.Control
                    type="number"
                    value={order.partner_cancellation_price}
                    onChange={handleChange}
                  />
                  <FormControlFeedback errors={validationErrors} field="partner_cancellation_price" />
                </Form.Group>
                <Form.Group controlId="partner_cancellation_currency">
                  <Form.Select
                    value={order.partner_cancellation_currency}
                    onChange={handleChange}
                  >
                    <option value="">Select currency</option>
                    <option>EUR</option>
                    <option>HUF</option>
                  </Form.Select>
                  <FormControlFeedback errors={validationErrors} field="partner_cancellation_currency" />
                </Form.Group>
              </td>
            </tr>
          </>

        ) }
        <tr>
          <td className="bg-body-secondary">
            Vehicle Category
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="category.name">
              <Form.Control
                className="bg-warning-subtle"
                type="text"
                value={order.category.name}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="category.name" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Capacity</td>
          <td>
            <Form.Group controlId="category.capacity">
              <Form.Control
                type="number"
                value={order.category.capacity}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="category.capacity" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Max. Luggage</td>
          <td>
            <Form.Group controlId="category.max_luggage">
              <Form.Control
                type="number"
                value={order.category.max_luggage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="category.max_luggage" />
            </Form.Group>
          </td>
        </tr>

        {/* Payment Information */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Payment Information</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Base Price
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="original_price">
              <Form.Control
                className="bg-warning-subtle"
                type="number"
                value={order.original_price}
                onChange={(e) => {
                  setOrder({ ...order, original_price: e.target.value, price: e.target.value });
                }}
              />
              <FormControlFeedback errors={validationErrors} field="original_price" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Final Price (Discounted)</td>
          <td>
            <Form.Group controlId="price">
              <Form.Control
                type="number"
                value={order.price}
                onChange={(e) => {
                  setOrder({
                    ...order,
                    price: e.target.value,
                    discount: {
                      ...order.discount,
                      percentage: Math.floor(
                        ((order.original_price - e.target.value) / order.original_price) * 100,
                      ),
                    },
                  });
                }}
              />
              <FormControlFeedback errors={validationErrors} field="price" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Currency
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="currency">
              <Form.Select
                className="bg-warning-subtle"
                value={order.currency}
                onChange={handleChange}
              >
                <option value="">Select currency</option>
                <option>EUR</option>
                <option>HUF</option>
              </Form.Select>
              <FormControlFeedback errors={validationErrors} field="currency" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">
            Type
            <i className="fa fa-asterisk text-danger bg-body-secondary" />
          </td>
          <td>
            <Form.Group controlId="payment.type">
              <Form.Select
                className="bg-warning-subtle"
                value={order.payment?.type}
                onChange={handleChange}
              >
                <option value="">Select a payment type</option>
                {paymentTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Select>
              <FormControlFeedback errors={validationErrors} field="payment.type" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Paid</td>
          <td>
            <Form.Group controlId="payment.paid">
              <Form.Check
                type="checkbox"
                disabled={order.payment?.barion_payment_id}
                checked={order.payment?.paid || order.payment?.barion_payment_id}
                onChange={handleChange}
              />
            </Form.Group>
          </td>
        </tr>

        {/* Discount */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>Discount</th>
        </tr>
        <tr>
          <td className="bg-body-secondary">Code</td>
          <td>
            <Form.Group controlId="discount.code">
              <Form.Control
                type="text"
                value={order.discount.code}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="discount.code" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Percentage</td>
          <td>
            <Form.Group controlId="discount.percentage">
              <Form.Control
                type="number"
                disabled
                value={order.discount.percentage}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="discount.percentage" />
            </Form.Group>
          </td>
        </tr>

        {/* Billing Information */}
        <tr>
          <th className="bg-dark-subtle" colSpan={2}>
            <div className="d-flex m-0 p-1 bg-dark-subtle">
              <span>Billing Information</span>
              <Button
                disabled={!order.partner_id}
                size="sm"
                variant="secondary"
                className="ms-auto"
                onClick={() => setBillingDetailsFromPartner(order.partner_id)}
              >
                Override based on Partner
                { ' ' }
                <span className="fa fa-refresh" />
              </Button>
            </div>

          </th>
        </tr>
        <tr>
          <td className="bg-body-secondary">Billing Name</td>
          <td>
            <Form.Group controlId="billing.name">
              <Form.Control
                type="text"
                value={order.billing.name}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="billing.name" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Billing Address</td>
          <td>
            <Form.Group controlId="billing.address">
              <Form.Control
                type="text"
                value={order.billing.address}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="billing.address" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Billing City</td>
          <td>
            <Form.Group controlId="billing.city">
              <Form.Control
                type="text"
                value={order.billing.city}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="billing.city" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Billing Postcode</td>
          <td>
            <Form.Group controlId="billing.postcode">
              <Form.Control
                type="text"
                value={order.billing.postcode}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="billing.postcode" />
            </Form.Group>
          </td>
        </tr>
        <tr>
          <td className="bg-body-secondary">Billing Country</td>
          <td>
            <Form.Group controlId="billing.country">
              <Form.Control
                type="text"
                value={order.billing.country}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="billing.country" />
            </Form.Group>
          </td>
        </tr>
      </Table>

      <Modal.Footer>
        <Button
          onClick={handleSubmit}
        >
          Preview
        </Button>
      </Modal.Footer>
    </>
  );
}

export default OrderInputForm;

/**
 * Updates the state based on the input from a form element.
 * This function can handle inputs nested within objects by using dot notation in the
 * input's id (e.g., "parentKey.childKey"). It supports text, number, and checkbox inputs.
 *
 * @param {Event} e - The event triggered by the input change
 * @param {Function} callback - The function to call with the updated state.
 * @param {Object} object - The current state object that will be updated based on the input change.
 * @returns {void}
 */
export default function setStateFromInput(e, callback) {
  const [parentKey, childKey] = e.target.id.split('.');

  // eslint-disable-next-line no-nested-ternary
  let value;
  switch (e.target.type) {
    case 'number':
      value = Number(e.target.value);
      break;
    case 'checkbox':
      value = e.target.checked;
      break;
    case 'select-one':
      value = e.currentTarget.value;
      break;
    default:
      value = e.target.value;
  }

  if (childKey) {
    callback((prevState) => ({
      ...prevState,
      [parentKey]: {
        ...prevState[parentKey],
        [childKey]: value,
      },
    }));
    return;
  }

  callback((prevState) => ({
    ...prevState,
    [parentKey]: value,
  }));
}

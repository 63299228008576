import { post } from './API';

export default {
  postBooking: async (order) => {
    // eslint-disable-next-line no-param-reassign
    order.passengers.amount = Number(order.passengers.adults)
        + Number(order.passengers.infant_carrier)
        + Number(order.passengers.booster_seat)
        + Number(order.passengers.convertible_seat);

    await post('/bookings', { ...order });
  },
};

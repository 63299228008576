import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { logout } from '../Controllers/AuthController';

function Header({ isAuthed, session }) {
  const [isLoading, setIsLoading] = useState(false);

  const onLogout = async () => {
    setIsLoading(true);
    const logoutAction = await logout();

    if (logoutAction) {
      setIsLoading(false);
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  return (
    <header className="p-3 bg-dark text-white">
      <div className="container-custom">
        <div className="d-flex flex-wrap align-items-center justify-content-lg-start">
          <div>
            <h1>CRM</h1>
          </div>
          <ul className="nav me-auto ms-2 mb-2 justify-content-center mb-md-0">
            {
                        isAuthed && (
                        <>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/home"
                            >
                              Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/stats"
                            >
                              Stats
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/trips"
                            >
                              Trips
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/orders"
                            >
                              Orders
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/partners"
                            >
                              Partners
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/operators"
                            >
                              Operators
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className={({ isActive }) => `nav-link px-2${isActive ? ' text-secondary' : ' text-light'}`}
                              to="/partner-sync"
                            >
                              Sync
                            </NavLink>
                          </li>
                        </>
                        )
                        }
          </ul>

          { isAuthed && (
          <div className="me-4 mb-2 mb-md-0 d-none d-md-block">
            Hello,
            {' '}
            { session.givenName }
          </div>
          )}

          <div>
            { !isAuthed && (
            <a href="/login" className="btn btn-outline-light me-2">
              <i className="fa fa-user" />
              {' '}
              Login
            </a>
            )}
            { isAuthed && (
              <button
                type="button"
                // eslint-disable-next-line no-restricted-globals
                onClick={onLogout}
                className="btn btn-outline-light me-2"
              >
                { !isLoading ? <>Logout</> : <i className="fa fa-spin fa-spinner" /> }
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';

import logo from '../Common/Images/devologyio_logo.png';

function Footer() {
  return (
    <div className="container mt-5">
      <hr />
      <div className="d-flex">
        <div className="flex-grow-1">
          <img src={logo} alt="DEVOLOGY.IO logo" />
        </div>
        <a className="ms-auto text-end" href="https://devology.io/">Development by DEVOLOGY.IO</a>
      </div>
    </div>
  );
}

export default Footer;

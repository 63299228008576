import { get, post } from '../Services/API';
import { getToken, putToken } from '../Services/BearerToken';

const SESSION_TOKEN = getToken();

// Checks whether the user is authenticated
export async function isAuthenticated() {
  if (!SESSION_TOKEN) {
    return false;
  }

  try {
    const response = await get('/user/session');

    if (response.status === 200) {
      return response.data;
    }
  } catch {
    return false;
  }

  return false;
}

// Logs the user out
export async function logout() {
  if (!SESSION_TOKEN) { return false; }

  try {
    const response = await post('/user/logout');

    if (response.status === 200) {
      return true;
    }
  } catch {
    return false;
  }

  return false;
}

// Logs the user in
export async function login(username, password) {
  try {
    const response = await post('/login', { username, password });
    if (response.status === 200 && !response.data.data.ChallengeParameters) {
      putToken(response.data.data.idToken);
      return true;
    }

    if (response.status === 200 && response.data.data.ChallengeParameters) {
      return {
        challengeName: response.data.data.ChallengeName,
        userIdForSRP: response.data.data.ChallengeParameters?.USER_ID_FOR_SRP,
        session: response.data.data.Session,
      };
    }
  } catch (err) {
    throw new Error(err.response?.data?.message ? err.response.data.message : err.message);
  }

  return false;
}

// Password reset challenge
export async function passwordResetChallenge(name, session, userIdForSRP, password, username) {
  try {
    const response = await post('/user/password-reset-challenge', {
      name, session, userIdForSRP, password, username,
    });

    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    throw new Error(err.response?.data?.message ? err.response.data.message : err.message);
  }

  return false;
}

// Confirm forgot password verification code
export async function confirmForgotPassword(username, password, code) {
  try {
    const response = await post('/user/confirm-forgot-password', {
      username, password, code,
    });

    if (response.status === 200) {
      return true;
    }
  } catch (err) {
    throw new Error(err.response?.data?.message ? err.response.data.message : err.message);
  }

  return false;
}

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import _ from 'lodash';
import Error from '../../../Components/Error';
import BookingService from '../../../Services/BookingService';
import { get } from '../../../Services/API';
import setStateFromInput from '../../../Utils/setStateFromInput';

function OrderLineAdjustmentsModal({
  originalOrder = {}, show = false, handleClose,
}) {
  const defaultOrder = {
    send_notification_to_customer: false,
  };

  const [order] = useState(_.defaults(originalOrder, defaultOrder));
  const [orderLines, setOrderLines] = useState([]);

  const defaultNewLine = {
    credit: 0,
    debit: 0,
    credit_operator: 0,
    currency: 'EUR',
    description: '',
  };

  const [newLine, setNewLine] = useState(defaultNewLine);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchOrderLines = async () => {
    setLoading(true);
    const response = await get('bookings/order-lines', { _id: order._id });
    setLoading(false);
    if (response?.data.data?.lines) {
      setOrderLines(response.data.data.lines);
    }
  };

  const debouncedFetchOrderLines = _.debounce(() => {
    fetchOrderLines();
  }, 500);

  useEffect(() => {
    debouncedFetchOrderLines();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      let bookingOrderLines = [];
      if (order.order_lines && _.isArray(order.order_lines)) {
        bookingOrderLines = order.order_lines;
      }

      bookingOrderLines.push(newLine);

      await BookingService.postBooking({ ...order, order_lines: bookingOrderLines });

      setOrderLines([]);
      setNewLine(defaultNewLine);

      setTimeout(() => { setLoading(false); debouncedFetchOrderLines(); }, 2000);
    } catch (err) {
      setLoading(false);
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        // setValidationErrors(err.response.data?.errors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          Order Line Adjustments
          { ' ' }
          <i className={`fa ${loading ? 'fa-spinner fa-spin' : ''}`} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <Table responsive striped bordered hover className="mt-2">
          <thead>
            <tr>
              <th />
              <th>Input Currency</th>
              <th>Debit</th>
              <th>Credit Operator</th>
              <th>Credit</th>
              <th>Description</th>
              {order.operator_id ? (
                <th>
                  Operator Balance (
                  {order.operator?.settlement_currency}
                  )
                </th>
              ) : null}
              <th>
                Balance (
                {order.currency}
                )
              </th>
            </tr>
          </thead>
          <tbody>
            {orderLines.map((line) => (
              <tr>
                <td />
                <td />
                <td>
                  {line.debit}
                  {' '}
                  {line.currency}
                </td>
                <td>
                  {line.credit_operator}
                  {' '}
                  {line.currency}
                </td>
                <td>
                  {line.credit}
                  {' '}
                  {line.currency}
                </td>
                <td>{line.description}</td>
                {order.operator_id ? (
                  <td>
                    {line.balance_operator.toFixed(2)}
                  </td>
                ) : null}
                <td>
                  {line.balance.toFixed(2)}
                </td>
              </tr>
            ))}
            {order.status !== 'Completed' && (
            <tr>
              <td>
                <Button
                  variant="secondary"
                  onClick={handleSubmit}
                  disabled={loading}
                  size="sm"
                >
                  <span className="fa fa-plus" />
                </Button>
              </td>
              <td>
                <select
                  id="currency"
                  className="form-control"
                  value={newLine.currency}
                  onChange={(e) => setStateFromInput(e, setNewLine, newLine)}
                >
                  <option>EUR</option>
                  <option>HUF</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  id="debit"
                  className="form-control"
                  value={newLine.debit}
                  placeholder="Debit"
                  onChange={(e) => setStateFromInput(e, setNewLine, newLine)}
                />
              </td>
              <td>
                <input
                  type="number"
                  id="credit_operator"
                  className="form-control"
                  placeholder="Credit Operator"
                  value={newLine.credit_operator}
                  onChange={(e) => setStateFromInput(e, setNewLine, newLine)}
                />
              </td>
              <td>
                <input
                  type="number"
                  id="credit"
                  className="form-control"
                  placeholder="Credit"
                  value={newLine.credit}
                  onChange={(e) => setStateFromInput(e, setNewLine, newLine)}
                />
              </td>
              <td>
                <input
                  type="string"
                  id="description"
                  className="form-control"
                  placeholder="Description"
                  value={newLine.description}
                  onChange={(e) => setStateFromInput(e, setNewLine, newLine)}
                />
              </td>
            </tr>
            ) }
          </tbody>
        </Table>
        <Modal.Footer>
          <Button
            onClick={window.print}
            disabled={loading}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default OrderLineAdjustmentsModal;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../Controllers/AuthController';

export default function Login({
  setEmail, email, setChallengeResponse, setError, setIHaveReceivedACode,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const resp = await login(email, password);

      if (resp.challengeName) {
        setChallengeResponse(resp);
        return true;
      }

      if (resp === true) {
        navigate('/home', { replace: true });
        setLoading(false);
        // setIsAuthed(true);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return true;
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

    return false;
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="mb-3">
        <label htmlFor="emailInput" className="form-label">Email address</label>
        <input
          type="email"
          className="form-control"
          id="emailInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="passwordInput" className="form-label">Password</label>
        <input
          type="password"
          className="form-control"
          id="passwordInput"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        disabled={loading}
        className="btn btn-primary w-100"
      >
        Sign-in
      </button>

      <button
        type="button"
        className="btn btn-link w-100 mt-2"
        onClick={() => { setIHaveReceivedACode(true); }}
      >
        I have received a verification code
      </button>
    </form>
  );
}

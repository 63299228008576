import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Error from '../../../Components/Error';
import fetchGuides from '../../../Services/PickupGuides.service';
import BookingService from '../../../Services/BookingService';

function PickupGuideInputModal({
  show, handleClose, fetchOrders, originalOrder,
}) {
  const [error, setError] = useState('');
  const [guides, setGuides] = useState([]);
  const [decoratedGuides, setDecoratedGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(originalOrder);

  const decoratePickupGuideOption = (option) => {
    if (!option) return null;

    return {
      id: option.sys.id,
      label: `${option.fields.title}`,
    };
  };

  const fetchPickupGuides = async () => {
    try {
      const response = await fetchGuides();
      setGuides(response.data?.data?.items);
    } catch (err) {
      setError('Something bad happened. Try again later...');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await BookingService.postBooking({
        ...order,
        send_notification_to_customer: false,
      });
      fetchOrders();
      handleClose();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setError('Validation has failed');
      }
    } finally {
      setLoading(false);
    }
  };

  const updatePickupGuide = () => {
    if (order.contentful_pickup_guide_id && guides.length !== 0) {
      const decoratedGuide = decoratePickupGuideOption(
        guides.find((guide) => guide.sys.id === order.contentful_pickup_guide_id),
      );

      if (decoratedGuide) {
        setSelectedGuide([decoratedGuide]);
      }
    }
  };

  useEffect(() => {
    let pickupGuideId;

    if (Array.isArray(selectedGuide) && selectedGuide.length === 1) {
      pickupGuideId = selectedGuide[0].id;

      if (order.contentful_pickup_guide_id !== pickupGuideId) {
        setOrder((prev) => ({
          ...prev,
          contentful_pickup_guide_id: pickupGuideId,
        }));
      }
    }
  }, [selectedGuide]);

  useEffect(() => {
    const guidesTypeAhead = [];

    guides.forEach((guideRaw) => {
      guidesTypeAhead.push(
        decoratePickupGuideOption(guideRaw),
      );
    });

    setDecoratedGuides(guidesTypeAhead);
  }, [guides]);

  useEffect(() => {
    updatePickupGuide();
  }, [order]);

  useEffect(() => {
    if (show) fetchPickupGuides();
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Assign Pickup Guide
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <div className="row">
          <Form.Group className="mb-3 col-12" controlId="operator_id">
            <Form.Label>Guide</Form.Label>
            <Typeahead
              placeholder="Select a guide"
              id="operator_id"
              onChange={setSelectedGuide}
              options={decoratedGuides}
              selected={selectedGuide}
            />

          </Form.Group>
        </div>

      </Modal.Body>

      <Modal.Footer>
        <button type="button" disabled={loading} className="btn btn-primary" onClick={handleSubmit}>
          {!loading && 'Save' }
          {loading && <i className="fa fa-spinner" />}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PickupGuideInputModal;

import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import _ from 'lodash';
import Error from '../../Components/Error';
import PartnerInputModal from './Components/PartnerInputModal';
import useModalState from '../../Hooks/useModalState';
import PartnersService from '../../Services/PartnersService';

function Partners() {
  const [partners, setPartners] = useState([]);
  const [error, setError] = useState('');

  // New or existing order change
  const showPartnerInputModal = useModalState();

  const fetchPartners = async () => {
    try {
      const fetchedPartners = await PartnersService.fetchPartners();
      setPartners(fetchedPartners?.data?.data?.partners);
    } catch (err) {
      setError(error);
    }
  };

  const debouncedFetchPartners = _.debounce(() => {
    fetchPartners();
  }, 500);

  useEffect(() => {
    debouncedFetchPartners();
    return () => debouncedFetchPartners.cancel();
  }, []);

  return (
    <>
      <Error error={error} setError={setError} />

      <Button onClick={() => {
        showPartnerInputModal.show({});
      }}
      >
        Add New Partner
      </Button>

      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>E-mail address</th>
            <th>Website</th>
            <th>Phone number</th>
            <th>Active</th>
            <th>UUID</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {partners.map((partner) => (
            <tr key={partner.id}>
              <td>{partner.name}</td>
              <td>{partner.email}</td>
              <td>{partner.website}</td>
              <td>{partner.phone_number}</td>
              <td>{partner.active ? 'Yes' : 'No'}</td>
              <td>{partner.Id}</td>
              <td>
                <Button
                  size="sm"
                  onClick={() => {
                    showPartnerInputModal.show(partner);
                  }}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showPartnerInputModal.isVisible && (
      <PartnerInputModal
        originalPartner={showPartnerInputModal.selectedObject}
        show={showPartnerInputModal.isVisible}
        fetchPartners={fetchPartners}
        handleClose={showPartnerInputModal.hide}
      />
      ) }
    </>
  );
}

export default Partners;

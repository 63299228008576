/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Button, Alert,
} from 'react-bootstrap';
import _ from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import Error from '../../../Components/Error';
import FormControlFeedback from '../../../Components/FormControlFeedback';
import BookingService from '../../../Services/BookingService';
import setStateFromInput from '../../../Utils/setStateFromInput';
import OperatorService from '../../../Services/OperatorService';

function OperatorInputModal({
  originalOrder = {}, show = false, handleClose, fetchOrders,
}) {
  const defaultOrder = {
    send_notification_to_customer: false,
    operator_price: 0,
    operator_currency: null,
    operator_cash_from_client: null,
    operator_cash_from_client_currency: null,
    operator_comments: !originalOrder.operator_id ? originalOrder.comments : null,
  };

  const [order, setOrder] = useState(_.defaults(originalOrder, defaultOrder));

  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [decoratedOperators, setDecoratedOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([]);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState([]);
  const [driverPortal, setDriverPortal] = useState('');

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await BookingService.postBooking(order);
      fetchOrders();
      handleClose();
    } catch (err) {
      setError(err.response.data?.message);

      if (err.response.data.errors) {
        setValidationErrors(err.response.data?.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const decorateOperatorOption = (option) => {
    if (!option) return null;

    return {
      id: option.Id,
      label: `${option.name}`,
    };
  };

  const updateOperator = () => {
    if (order.operator_id && operators.length !== 0) {
      const decoratedPartner = decorateOperatorOption(
        operators.find((operator) => operator.Id === order.operator_id),
      );

      if (decoratedPartner) {
        setSelectedOperator([decoratedPartner]);
      }
    }
  };

  const fetchOperators = async () => {
    const response = await OperatorService.fetchOperators();
    setOperators(response?.data.data?.operators);
  };

  /**
   * Handle input change
   * @param {object} e
   * @returns void
   */
  const handleChange = (e) => {
    setStateFromInput(e, setOrder, order);
  };

  const debouncedFetchOperators = _.debounce(() => {
    fetchOperators();
  }, 500);

  const addLinkToDriverPortal = () => `Driver Portal: https://portal.pickuphungary.com/driver/${order.operator_id}/${order.booking_reference}`;

  useEffect(() => {
    debouncedFetchOperators();
  }, [originalOrder]);

  useEffect(() => {
    let operatorId;

    if (Array.isArray(selectedOperator) && selectedOperator.length === 1) {
      operatorId = selectedOperator[0].id;

      if (order.partner_id !== operatorId) {
        setOrder((prev) => ({
          ...prev,
          operator_id: operatorId,
        }));
      }
    }
  }, [selectedOperator]);

  useEffect(() => {
    const operatorsTypeAhead = [];

    operators.forEach((operatorRaw) => {
      operatorsTypeAhead.push(
        decorateOperatorOption(operatorRaw),
      );
    });

    setDecoratedOperators(operatorsTypeAhead);
    updateOperator();
  }, [operators]);

  useEffect(() => {
    updateOperator();
  }, [order]);

  useEffect(() => {
    setDriverPortal(addLinkToDriverPortal());
  }, [order.operator_id]);

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Assign Operator
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Error
          error={error}
          setError={setError}
        />

        <div className="row">
          <Form.Group className="mb-3 col-12" controlId="operator_id">
            <Form.Label>Operator</Form.Label>
            <Typeahead
              placeholder="Select an operator"
              id="operator_id"
              onChange={setSelectedOperator}
              options={decoratedOperators}
              selected={selectedOperator}
            />
            {/* <Form.Select
              value={order.operator_id}
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select an operator</option>
              {operators.map((value) => (
                <option
                  key={value.Id}
                  value={value.Id}
                >
                  {value.name}
                </option>
              ))}
            </Form.Select> */}

            <FormControlFeedback errors={validationErrors} field="operator_booking_reference" />
          </Form.Group>

          <Form.Group className="mb-3 col-12" controlId="operator_booking_reference">
            <Form.Label>Operator #</Form.Label>
            <Form.Control
              value={order.operator_booking_reference}
              onChange={(e) => handleChange(e)}
            />

            <FormControlFeedback errors={validationErrors} field="operator_booking_reference" />
          </Form.Group>

          <Form.Group className="mb-3 col-12 col-md-6" controlId="operator_price">
            <Form.Label>Operator Price</Form.Label>
            <Form.Control
              value={order.operator_price}
              type="number"
              onChange={(e) => handleChange(e)}
            />

            <FormControlFeedback errors={validationErrors} field="operator_price" />
          </Form.Group>

          <Form.Group className="mb-3 col-12 col-md-6" controlId="operator_currency">
            <Form.Label>Currency</Form.Label>
            <Form.Select
              value={order.operator_currency}
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select currency</option>
              <option>EUR</option>
              <option>HUF</option>
            </Form.Select>
            <FormControlFeedback errors={validationErrors} field="operator_currency" />
          </Form.Group>

          {(order.payment?.type === 'Cash' || order.payment?.type === 'Mixed Payment') && (
          <Alert variant="warning" onClose={() => setError(false)} dismissible>
            <Alert.Heading>Payment Info</Alert.Heading>
            <p>
              Payment is Cash or Mixed payment.
              Provide details to operator regarding collection.
            </p>
          </Alert>
          ) }

          {(order.payment?.type === 'Cash' || order.payment?.type === 'Mixed Payment') && (
            <>
              <Form.Group className="mb-3 col-12 col-md-6" controlId="operator_cash_from_client">
                <Form.Label>Cash from Client</Form.Label>
                <Form.Control
                  value={order.operator_cash_from_client}
                  type="number"
                  onChange={(e) => handleChange(e)}
                />

                <FormControlFeedback errors={validationErrors} field="operator_cash_from_client" />
              </Form.Group>

              <Form.Group className="mb-3 col-12 col-md-6" controlId="operator_cash_from_client_currency">
                <Form.Label>Cash from Client - Currency</Form.Label>
                <Form.Select
                  value={order.operator_cash_from_client_currency}
                  onChange={(e) => handleChange(e)}
                >
                  <option value="">Select currency</option>
                  <option>EUR</option>
                  <option>HUF</option>
                </Form.Select>
                <FormControlFeedback errors={validationErrors} field="operator_cash_from_client_currency" />
              </Form.Group>
            </>
          )}

          <Form.Group className="mb-3 col-12" controlId="operator_comments">
            <Form.Label className="d-flex">
              Operator Comments
            </Form.Label>
            <Form.Control
              as="textarea"
              value={order.operator_comments}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>

          { (order.partner.driver_portal_enabled === 1 && order.operator_id && (
          <p className="alert alert-primary">
            {driverPortal}
          </p>
          )) }
        </div>

        <Modal.Footer>
          <Button
            onClick={handleSubmit}
            disabled={loading}
          >
            Assign operator
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default OperatorInputModal;

import { useState } from 'react';

export default function useModalState() {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const show = (object = {}) => {
    setSelectedObject(object); // Set the selected order when showing the modal
    setIsVisible(true);
  };

  const hide = () => {
    setIsVisible(false);
    setSelectedObject({});
  };

  return {
    isVisible, show, hide, selectedObject,
  };
}

import React, { useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import format from '../../../Utils/dateFormat';
import thousandSeparator from '../../../Utils/numberFormat';

export default function TripConfirmationModal({ order, show, handleClose }) {
  const printModalContent = () => {
    const modal = document.querySelector('.modal .modal-content .modal-body');
    if (!modal) {
      console.error('Modal element not found.');
      return;
    }

    const modalContent = modal.innerHTML;
    const printWindow = window.open('', '', 'height=800,width=600');

    if (!printWindow) {
      console.error('Failed to open print window.');
      return;
    }

    printWindow.document.write(`<html><head><title>${order.booking_reference}</title>`);
    printWindow.document.write('<style>');
    printWindow.document.write(`
          @media print {
            body, html {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
              font-family: "Verdana", Arial;
              overflow: visible;
            }
            .modal-content {
              overflow: visible !important;
              height: auto !important;
            }

            table {
                width: 100%;
                border-collapse: collapse;
              }
              table, th, td {
                border: 1px solid lightgray;
              }
              th, td {
                padding: 8px;
                text-align: left;
              }
          }
        `);
    printWindow.document.write('</style></head><body>');
    printWindow.document.write('<div class="modal">');
    printWindow.document.write(modalContent);
    printWindow.document.write('</div></body></html>');
    printWindow.document.close();

    // Ensure the new window's document is fully loaded before printing
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  useEffect(() => {
    printModalContent();
  }, [order]);

  return (
    <Modal show={show} onHide={handleClose} fullscreen id="printSection">
      <Modal.Header closeButton />
      <Modal.Body>
        <>

          <div style={{ 'margin-bottom': '2rem' }}>
            <div style={{ 'text-align': 'right' }}>
              <img src="/ph-logo.png" width="350px" alt="Pickup Hungary logo" />

              <h1>
                Pickup Hungary Trip
              </h1>
            </div>

            <h2>
              ID:
              {' '}
              {order.booking_reference}
            </h2>
            <p>
              <b>Attn:</b>
              {' '}
              {order.partner.name}
            </p>
            <p>
              Created Date:
              {new Date(order.created_at).toLocaleString()}
            </p>

            <p>
              Thank you for choosing Pickup Hungary!
              Any questions, please contact
              {' '}
              <a href="mailto:info@pickuphungary.com">info@pickuphungary.com</a>
            </p>

            <p>
              <b>Important Information</b>
              <ul>
                <li>
                  As per our cancellation policy, please notify us of changes or
                  cancellations no later than 24 hours before your trip.
                </li>
                <li>
                  Your driver will be assigned on the day prior of your booking.
                </li>
                <li>
                  Your driver will contact you on your contact number 30 minutes
                  before your booking.
                </li>
                <li>
                  <b>Economy / Comfort category</b>
                  {' '}
                  If your pick-up is at the airport, please refer to
                  our
                  {' '}
                  <a href="https://d1i92x3swm0eql.cloudfront.net/Meeting%20at%20Airport_%20On-Call%20Service%20-%20CAR.pdf">on-call meeting guide </a>
                  for Economy / Comfort
                  {' '}
                  transfers.
                </li>
                <li>
                  <b>Business / Premium category</b>
                  {' '}
                  Please note, the driver will wait for you at your gate with your nameplate.
                </li>
                <li>
                  <b>Buses</b>
                  {' '}
                  If your pick-up is at the airport, please refer to our
                  {' '}
                  <a href="http://d1i92x3swm0eql.cloudfront.net/Meeting%20at%20Airport_%20On-Call%20Service%20-%20CAR.pdf">pickup guide</a>
                  {' '}
                  for buses.
                </li>
                <li>
                  In case of delays, your driver will wait for up to 90 minutes free
                  of charge. Please notify us of any delays as soon as possible,
                  so we can adjust your pick-up time.
                </li>
              </ul>
            </p>

          </div>

          <Table style={{ 'margin-top': '9rem' }} striped responsive>
            <tbody>
              <tr>
                <td className="bg-body-secondary">
                  Order Status
                </td>
                <td style={{ 'font-weight': 'bold' }}>
                  {order.status}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Full Name
                </td>
                <td>{order.full_name}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  E-mail address
                </td>
                <td>{order.email_address}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Phone Number
                </td>
                <td>{order.phone_number}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Date
                </td>
                <td>{order.date_datetime && format(order.date_datetime)}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Return Date
                </td>
                <td>{order.return_date_datetime && format(order.return_date_datetime)}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  From
                </td>
                <td>{order.destination_from?.address}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  To
                </td>
                <td>{order.destination_to?.address}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Flight Number
                </td>
                <td>{order.flight_number}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Comments
                </td>
                <td>{order.comments}</td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Total Passengers
                </td>
                <td>
                  {order.passengers.amount}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Adults
                </td>
                <td>
                  {order.passengers.adults}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">Kids: Convertible Seat</td>
                <td>
                  {order.passengers.convertible_seat}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">Kids: Booster Seat</td>
                <td>
                  {order.passengers.booster_seat}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">Kids: Infant Carrier</td>
                <td>
                  {order.passengers.infant_carrier}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Vehicle Category
                </td>
                <td>
                  {`${order.category.name}, max. capacity: ${order.category.capacity}, max. luggage: ${order.category.max_luggage},  `}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Price
                </td>
                <td>
                  {thousandSeparator(order.price)}
                  {' '}
                  {order.currency}
                </td>
              </tr>
              <tr>
                <td className="bg-body-secondary">
                  Payment Type
                </td>
                <td>
                  {order.payment.type}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      </Modal.Body>
    </Modal>
  );
}

import React, { useState } from 'react';

function SortByColumn({ column, sortFn }) {
  const [isSorted, setIsSorted] = useState(false);

  const sort = () => {
    setIsSorted(!isSorted);
    sortFn(column, isSorted);
  };

  return (
    <>
      { !isSorted ? (
        <button
          type="button"
          onClick={sort}
          className="btn btn-outline-primary btn-sm"
        >
          <span className="fa fa-arrow-down-short-wide" />
        </button>
      ) : null}
      { isSorted ? (
        <button
          type="button"
          onClick={sort}
          className="btn btn-outline-primary btn-sm"
        >
          <span className="fa fa-arrow-down-wide-short" />
        </button>
      ) : null }
    </>

  );
}

export default SortByColumn;

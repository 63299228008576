import React from 'react';
import { Alert } from 'react-bootstrap';

function Error({ error, setError }) {
  return (
    error && (
    <Alert variant="danger" onClose={() => setError(false)} dismissible>
      <Alert.Heading>An error occured</Alert.Heading>
      <p>{error}</p>
    </Alert>
    )
  );
}

export default Error;

import axios from 'axios';
import { getToken } from './BearerToken';

/** Add interceptor for bearer token */
axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      // eslint-disable-next-line no-param-reassign
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export function get(url, data) {
  const params = {
    ...data,
  };

  return axios.get(process.env.REACT_APP_API_URL + url, { params });
}

export function post(url, data, params) {
  return axios.post(process.env.REACT_APP_API_URL + url, data, { params });
}

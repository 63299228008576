import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { passwordResetChallenge } from '../../../Controllers/AuthController';

// This is a mock component for demonstration.
// You would need to define how it handles the form submission.
function SolveChallenge({
  name, session, userIdForSRP, username, setError, setChallengeResponse, setSuccess,
}) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const resp = await passwordResetChallenge(name, session, userIdForSRP, password, username);

      if (resp === true) {
        setChallengeResponse(false);
        setLoading(false);
        setSuccess('Challenge solved. You can now log-in...');
        return true;
      }

      setError(resp.body);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

    return false;
  };
  if (name === 'NEW_PASSWORD_REQUIRED') {
    return (
      <>
        <h3 className="card-title text-center mb-4 mt-2">Your password needs to be changed</h3>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">New Passord</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary w-100"
          >
            Change password
          </button>
        </form>
      </>
    );
  }
}

SolveChallenge.propTypes = {
  name: PropTypes.string.isRequired,
  session: PropTypes.string.isRequired,
  userIdForSRP: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

export default SolveChallenge;

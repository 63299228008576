/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { debounce } from 'lodash';
import fetchPartnerBookingsForSync from '../../Services/PartnerIntegrationService';
import PartnersService from '../../Services/PartnersService';
import Error from '../../Components/Error';
import OrderInputModal from '../Orders/Components/OrderInputModal';
import { post } from '../../Services/API';
import format, { formatDate, formatTime } from '../../Utils/dateFormat';

function PartnerSync() {
  const [partnerBookings, setPartnerBookings] = useState([]);
  const [partners, setPartners] = useState([]);
  const [showOrderInputModal, setShowOrderInputModal] = useState(false);
  const [originalOrder, setOriginalOrder] = useState({});
  const [error, setError] = useState('');
  const [stateHash, setStateHash] = useState('');

  const fetchPartners = async () => {
    try {
      const fetchedPartners = await PartnersService.fetchPartners();
      setPartners(fetchedPartners?.data?.data?.partners);
    } catch (err) {
      setError(error);
    }
  };

  const handleClickOnView = (order = {}, hash = '') => {
    setOriginalOrder(order);
    setStateHash(hash);

    setTimeout(() => {
      setShowOrderInputModal(true);
    }, 100);
  };

  const findPartner = (partnerUuid) => {
    const partner = partners.find((entry) => entry.Id === partnerUuid);
    return partner.name;
  };

  const fetchPartnerBookings = async () => {
    try {
      const fetchedPartnerBookings = await fetchPartnerBookingsForSync();
      let results = fetchedPartnerBookings?.data?.data;

      results = results.map((booking) => {
        const bookingData = JSON.parse(booking.Data);
        const bookingMetadata = JSON.parse(booking.Metadata);

        return {
          ...booking,
          Data: bookingData,
          Metadata: bookingMetadata,
        };
      });

      setPartnerBookings(() => results || []);
    } catch (err) {
      setError(err.message);
    }
  };

  const processPartnerBooking = async () => {
    setPartnerBookings([]);

    try {
      await post(
        'partner-integrations/sync',
        {
          BookingId: originalOrder.partner_booking_reference,
          PartnerId: originalOrder.partner_id,
          flow: 'APPROVED',
          StateHash: stateHash,
        },
      );
      await fetchPartnerBookings();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeclinePartnerBooking = async (order = {}, hash = '') => {
    // eslint-disable-next-line no-alert
    if (!window.confirm('Are you sure you want to decline this booking? This action cannot be undone.')) {
      return; // Exit the function if the user cancels
    }

    setPartnerBookings([]);
    setStateHash(stateHash);

    try {
      await post(
        'partner-integrations/sync',
        {
          BookingId: order.partner_booking_reference,
          PartnerId: order.partner_id,
          flow: 'DECLINED',
          StateHash: hash,
        },
      );
      await fetchPartnerBookings();
    } catch (err) {
      setError(err.message);
    }
  };

  const debouncedFetchPartnerBookings = debounce(async () => {
    fetchPartnerBookings();
  }, 500);

  const debouncedFetchPartners = debounce(async () => {
    fetchPartners();
  }, 500);

  useEffect(() => {
    debouncedFetchPartnerBookings();
    debouncedFetchPartners();

    return () => {
      debouncedFetchPartnerBookings.cancel();
      debouncedFetchPartners.cancel();
    };
  }, []);

  return (
    <>
      <Error error={error} setError={setError} />
      <Table responsive striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>Partner</th>
            <th>Partner #</th>
            <th>Transfer Date</th>
            <th>Full Name</th>
            <th>Phone Number</th>
            <th>Pax</th>
            <th>Flight Number</th>
            <th>From</th>
            <th>To</th>
            <th>Price</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {partnerBookings.map((booking) => (
            <tr key={booking.BookingId}>
              <td>{findPartner(booking.PartnerId)}</td>
              <td>{booking.BookingId}</td>
              <td>
                {booking.Data.date_datetime ? (
                  <>
                    {format(booking.Data.date_datetime)}
                  </>
                ) : 'INCORRECT_DATE'}
              </td>
              <td>{booking.Data.full_name}</td>
              <td>{booking.Data.phone_number}</td>
              <td>{booking.Data.passengers.amount}</td>
              <td>{booking.Data.flight_number}</td>
              <td>{booking.Data.destination_from.address}</td>
              <td>{booking.Data.destination_to.address}</td>
              <td>
                {booking.Data.price}
                {' '}
                {booking.Data.currency}
              </td>
              <td>{booking.Status}</td>
              <td>
                <Button
                  className="d-block w-100"
                  onClick={() => handleClickOnView(
                    booking.Data,
                    booking.Metadata.state_hash,
                  )}
                >
                  View
                </Button>
                <Button
                  variant="danger"
                  className="mt-3 d-block w-100"
                  disabled={booking.Status === 'PENDING_CANCELLED'}
                  onClick={() => handleDeclinePartnerBooking(
                    booking.Data,
                    booking.Metadata.state_hash,
                  )}
                >
                  Declline
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showOrderInputModal ? (
        <OrderInputModal
          show={showOrderInputModal}
          handleClose={() => setShowOrderInputModal(false)}
          originalOrder={originalOrder}
          fetchOrders={processPartnerBooking}
        />
      ) : null}
    </>
  );
}

export default PartnerSync;

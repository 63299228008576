import { get } from '../../../Services/API';

/**
   * Fetch orders
   * @param {object} appliedFilters
   */
export default ({
  setFiltered,
  setIsLoading,
  setOrders,
  setTotalPages,
  setTotalOrders,
  setError,
}) => async (appliedFilters) => {
  window.scrollTo(0, 0);
  if (setFiltered) {
    setFiltered(false);
  }

  setIsLoading(true);
  try {
    const response = await get('/bookings', { filters: appliedFilters });
    setOrders(response.data?.data?.documents || []);
    setTotalPages(response.data?.data?.pages || 1);

    if (setTotalOrders) setTotalOrders(response.data?.data?.count);
  } catch (err) {
    setError(err.message);
  } finally {
    setIsLoading(false);
  }
};

import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import SolveChallenge from './Components/SolveChallenge';
import Login from './Components/Login';
import IHaveReceivedACode from './Components/IHaveReceivedACode';
import Error from '../../Components/Error';

function LoginPage({ setIsAuthed }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [challengeResponse, setChallengeResponse] = useState({});
  const [iHaveReceivedACode, setIHaveReceivedACode] = useState(false);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Sign-in to Your Account</h2>

              <Error error={error} setError={setError} />

              {success && (
              <Alert variant="success" onClose={() => setError(false)} dismissible>
                <Alert.Heading>OK</Alert.Heading>
                <p>{success}</p>
              </Alert>
              ) }

              { challengeResponse?.challengeName && (
                <SolveChallenge
                  name={challengeResponse.challengeName}
                  username={email}
                  session={challengeResponse.session}
                  userIdForSRP={challengeResponse.userIdForSRP}
                  setError={setError}
                  setChallengeResponse={setChallengeResponse}
                  setSuccess={setSuccess}
                />
              )}

              { iHaveReceivedACode && (
                <IHaveReceivedACode
                  setError={setError}
                  setIHaveReceivedACode={setIHaveReceivedACode}
                  setSuccess={setSuccess}
                />
              )}

              {!challengeResponse?.challengeName && !iHaveReceivedACode && (
              <Login
                setEmail={setEmail}
                email={email}
                setError={setError}
                setChallengeResponse={setChallengeResponse}
                setIHaveReceivedACode={setIHaveReceivedACode}
                setSuccess={setSuccess}
                setIsAuthed={setIsAuthed}
              />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function format(dateTime) {
  return dayjs(dateTime).utc().format('YYYY-MM-DD HH:mm');
}

export function formatDate(dateTime) {
  return dayjs(dateTime).utc().format('YYYY-MM-DD');
}

export function formatTime(dateTime) {
  return dayjs(dateTime).utc().format('HH:mm');
}

import { debounce, defaults } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import FormControlFeedback from '../../Components/FormControlFeedback';
import Error from '../../Components/Error';
import setStateFromInput from '../../Utils/setStateFromInput';
import { get } from '../../Services/API';
import OperatorService from '../../Services/OperatorService';
import Success from '../../Components/Succes';
import { formatDate, formatTime } from '../../Utils/dateFormat';
import thousandSeparator from '../../Utils/numberFormat';
import PartnersService from '../../Services/PartnersService';
import PieChart from '../../Components/PieChart';

function Stats() {
  const defaultStats = {
    operator_payment: {
      from: '',
      to: '',
      operator_id: '',
      show_profit: false,
      show_partner_information: false,
    },
    partner_payment: {
      from: '',
      to: '',
      payment_id: '',
    },
    global_stats_this_month: {
      from: formatDate(dayjs().startOf('month')),
      to: formatDate(dayjs().endOf('month')),
    },
    global_stats_lasth_month: {
      from: formatDate(dayjs().subtract(1, 'month').startOf('month')),
      to: formatDate(dayjs().subtract(1, 'month').endOf('month')),
    },
  };

  const [loadIn] = useState(true);
  const [stats, setStats] = useState(defaults({}, defaultStats));
  const [validationErrors, setValidationErrors] = useState([]);
  const [operators, setOperators] = useState([]);
  const [operatorPaymentStats, setOperatorPaymentStats] = useState({});
  const [partners, setPartners] = useState([]);
  const [partnerPaymentStats, setPartnerPaymentStats] = useState({});
  const [globalStats, setGlobalStats] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchOperators = async () => {
    try {
      const fetchedOperators = await OperatorService.fetchOperators();
      setOperators(fetchedOperators?.data?.data?.operators);
    } catch (err) {
      setError(error);
    }
  };

  const fetchPartners = async () => {
    try {
      const fetchedPartners = await PartnersService.fetchPartners();
      setPartners(fetchedPartners?.data?.data?.partners);
    } catch (err) {
      setError(error);
    }
  };

  const debouncedFetchOperators = debounce(() => {
    fetchOperators();
  }, 500);

  const debouncedFetchPartners = debounce(() => {
    fetchPartners();
  }, 500);

  const handleGlobalStatsThisMonthLoad = async () => {
    try {
      const response = await get(
        '/stats',
        {
          type: 'global_stats',
          filters: {
            date: [stats.global_stats_this_month.from, stats.global_stats_this_month.to],
            pageSize: 10000,
            status: ['Confirmed', 'Completed', 'Cancelled'],
            sort: { date_datetime: 'desc' },
          },
        },
      );
      setGlobalStats((prev) => ({ ...prev, this_month: response.data?.data?.stats }));
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    }
  };

  const handleGlobalStatsLastMonthLoad = async () => {
    try {
      const response = await get(
        '/stats',
        {
          type: 'global_stats',
          filters: {
            date: [stats.global_stats_lasth_month.from, stats.global_stats_lasth_month.to],
            pageSize: 10000,
            status: ['Confirmed', 'Completed', 'Cancelled'],
            sort: { date_datetime: 'desc' },
          },
        },
      );
      setGlobalStats((prev) => ({ ...prev, last_month: response.data?.data?.stats }));
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    }
  };

  const debouncedHandleStatsLoad = debounce(() => {
    handleGlobalStatsThisMonthLoad();
    handleGlobalStatsLastMonthLoad();
  }, 500);

  const handleOperatorPaymentSubmit = async () => {
    setLoading(true);

    try {
      const response = await get(
        '/stats',
        {
          type: 'operator_payment_stats',
          filters: {
            date: [stats.operator_payment.from, stats.operator_payment.to],
            pageSize: 10000,
            status: ['Confirmed', 'Completed', 'Cancelled'],
            sort: { date_datetime: 'desc' },
            show_profit: stats.operator_payment.show_profit,
            operators: [stats.operator_payment.operator_id],
          },
        },
      );
      setOperatorPaymentStats(response.data?.data?.stats);
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePartnerPaymentSubmit = async () => {
    setLoading(true);

    try {
      const response = await get(
        '/stats',
        {
          type: 'partner_trips_stats',
          filters: {
            date: [stats.partner_payment.from, stats.partner_payment.to],
            pageSize: 10000,
            status: ['Confirmed', 'Completed'],
            sort: { date_datetime: 'desc' },
            partners: [stats.partner_payment.partner_id],
          },
        },
      );
      setPartnerPaymentStats(response.data?.data?.stats);
    } catch (err) {
      setError(err.response?.data?.message);

      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle input change
   * @param {object} e
   * @returns void
   */
  const handleChange = (e) => {
    setStateFromInput(e, setStats, stats);
  };

  useEffect(() => {
    debouncedFetchOperators();
    debouncedFetchPartners();
    debouncedHandleStatsLoad();

    return () => {
      debouncedFetchOperators.cancel();
      debouncedFetchPartners.cancel();
      debouncedHandleStatsLoad.cancel();
    };
  }, [loadIn]);

  return (
    <>
      <h1>Stats</h1>

      <Error
        error={error}
        setError={setError}
      />

      <Success
        success={success}
        setSuccess={setSuccess}
      />

      <div className="card shadow-sm rounded">
        <div className="card-body p-4">
          {globalStats.this_month && (
          <div className="mb-4">
            <h2 className="text-center font-weight-bold">This Month So Far</h2>
            <div className="row">
              {[
                {
                  label: 'Profit',
                  value: globalStats.this_month.total_profit,
                  className: 'text-success',
                  icon: 'fa-euro-sign',
                },
                {
                  label: 'Revenue',
                  value: globalStats.this_month.total_revenue,
                  className: 'text-info',
                  icon: 'fa-euro-sign',
                },
                {
                  label: 'Total Transfers',
                  value: globalStats.this_month.amount_of_transfers,
                  className: 'text-warning',
                  icon: '',
                },
              ].map(({
                label, value, className, icon,
              }) => (
                <div key={label} className="col-12 col-md-4 mb-4 mb-md-0">
                  <div className="text-center">
                    <h3 className="font-weight-bold">{label}</h3>
                    <h4 className={className}>
                      {thousandSeparator(value)}
                      {icon && (
                      <>
                        {' '}
                        <span className={`fa ${icon}`} />
                      </>
                      )}
                    </h4>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4 row">
              <div className="offset-md-3 col-md-6 col-12">
                <PieChart data={globalStats.this_month.revenue_by_partners.map((partner) => ({
                  name: partner.name,
                  value: partner.revenue,
                }))}
                />
              </div>
            </div>

          </div>
          )}

          <hr />

          {globalStats.last_month && (
          <div className="mb-4 mt-4">
            <h2 className="text-center font-weight-bold">Last Month</h2>
            <div className="row">
              {[
                {
                  label: 'Profit',
                  value: globalStats.last_month.total_profit,
                  className: 'text-success',
                  icon: 'fa-euro-sign',
                },
                {
                  label: 'Revenue',
                  value: globalStats.last_month.total_revenue,
                  className: 'text-info',
                  icon: 'fa-euro-sign',
                },
                {
                  label: 'Total Transfers',
                  value: globalStats.last_month.amount_of_transfers,
                  className: 'text-warning',
                  icon: '',
                },
              ].map(({
                label, value, className, icon,
              }) => (
                <div key={label} className="col-12 col-md-4 mb-4 mb-md-0">
                  <div className="text-center">
                    <h3 className="font-weight-bold">{label}</h3>
                    <h4 className={className}>
                      {thousandSeparator(value)}
                      {icon && (
                      <>
                        {' '}
                        <span className={`fa ${icon}`} />
                      </>
                      )}
                    </h4>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4 row">
              <div className="offset-md-3 col-md-6 col-12">
                <PieChart data={globalStats.last_month.revenue_by_partners.map((partner) => ({
                  name: partner.name,
                  value: partner.revenue,
                }))}
                />
              </div>

            </div>
          </div>
          )}
        </div>
      </div>

      {/* <div className="card shadow-sm rounded mt-4">
        <div className="card-body p-4">
          <h2>Revenue by month</h2>
        </div>
      </div> */}

      <div className="card mt-4">
        <div className="card-body">
          <h2>Operator Payment Stats</h2>
          <Form className="row gy-3">
            <Form.Group className="col-md-4 col-12" controlId="operator_payment.from">
              <Form.Label>From</Form.Label>
              <Form.Control
                value={stats.operator_payment.from}
                onChange={handleChange}
                type="date"
              />
              <FormControlFeedback errors={validationErrors} field="date" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="operator_payment.to">
              <Form.Label>To</Form.Label>
              <Form.Control
                value={stats.operator_payment.to}
                onChange={handleChange}
                type="date"
              />
              <FormControlFeedback errors={validationErrors} field="date" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="operator_payment.operator_id">
              <Form.Label>Operator</Form.Label>
              <Form.Select
                value={stats.operator_payment.operator_id}
                onChange={handleChange}
              >
                <option value="">Select an operator</option>
                {operators.map((value) => (
                  <option
                    key={value.Id}
                    value={value.Id}
                  >
                    {value.name}
                  </option>
                ))}
              </Form.Select>
              <FormControlFeedback errors={validationErrors} field="operators" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="operator_payment.show_profit">
              <Form.Label>Show Profit</Form.Label>
              <Form.Check
                value={stats.operator_payment.show_profit}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="show_profit" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="operator_payment.show_partner_information">
              <Form.Label>Show Partner Information</Form.Label>
              <Form.Check
                value={stats.operator_payment.show_partner_information}
                onChange={handleChange}
              />
              <FormControlFeedback errors={validationErrors} field="show_partner_information" />
            </Form.Group>
            <div className="col-12 mt-3 align-self-end">
              <Button onClick={handleOperatorPaymentSubmit} size="lg" disabled={loading}>Search</Button>
            </div>
          </Form>

          {operatorPaymentStats.currency
          && (
            <>
              <div className="mt-3">
                <h3>Summary</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Gross Balance</th>
                      <th>Balance</th>
                      <th>Amount of Transfers</th>
                      {stats.operator_payment.show_profit && <th>Profit</th> }
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{`${thousandSeparator(operatorPaymentStats.gross_balance) ?? 0} ${operatorPaymentStats.currency}`}</td>
                      <td>{`${thousandSeparator(operatorPaymentStats.balance) ?? 0} ${operatorPaymentStats.currency}`}</td>
                      <td>{operatorPaymentStats.amount_of_transfers}</td>
                      {stats.operator_payment.show_profit && <td>{`${thousandSeparator(operatorPaymentStats.profit)} ${operatorPaymentStats.currency}`}</td> }
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="mt-3">
                <h3>Trips</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      {
                        stats.operator_payment.show_partner_information
                        && <th>Partner</th>
                      }
                      <th>Status</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Date & Time</th>
                      <th>Operator Price</th>
                      <th>Operator Cancellation Compensation</th>
                      <th>Cash Received</th>
                      {
                        stats.operator_payment.show_partner_information
                        && <th>Partner Price</th>
                      }
                      {
                        stats.operator_payment.show_partner_information
                        && <th>Partner Cancellation Compensation</th>
                      }
                      <th>From</th>
                      <th>To</th>
                      <th>Pax</th>

                    </tr>
                  </thead>
                  <tbody>
                    {operatorPaymentStats.trips.map((trip) => (
                      <tr style={{ textDecoration: trip.status === 'Cancelled' ? 'line-through' : 'none' }}>
                        <td>{trip.status}</td>
                        {
                          stats.operator_payment.show_partner_information
                          && <td>{trip.partner}</td>
                        }
                        <td>{trip.booking_reference}</td>
                        <td>{trip.name}</td>
                        <td>
                          {trip.category}
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          {`${formatDate(trip.date_time)} ${formatTime(trip.date_time)}`}
                        </td>
                        <td>
                          {trip.operator_price}
                          {' '}
                          {trip.operator_currency}
                        </td>
                        <td>
                          {trip.operator_cancellation_price}
                          {' '}
                          {trip.operator_cancellation_currency}
                        </td>
                        <td>
                          {trip.cash_received}
                          {' '}
                          {trip.cash_received_currency}
                        </td>
                        <td>
                          {
                          stats.operator_payment.show_partner_information
                          && (
                          <td>
                            {trip.price}
                            {' '}
                            {trip.currency}
                          </td>
                          )
                          }
                        </td>
                        <td>
                          {
                          stats.operator_payment.show_partner_information
                          && (
                          <td>
                            {trip.partner_cancellation_price}
                            {' '}
                            {trip.partner_cancellation_currency}
                          </td>
                          )
                          }
                        </td>
                        <td>
                          {trip.from}
                        </td>
                        <td>
                          {trip.to}
                        </td>
                        <td>
                          {trip.pax}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>

          )}
        </div>

      </div>

      <div className="card mt-4">
        <div className="card-body">
          <h2>Partner Trips Stats</h2>
          <Form className="row">
            <Form.Group className="col-md-4 col-12" controlId="partner_payment.from">
              <Form.Label>From</Form.Label>
              <Form.Control
                value={stats.partner_payment.from}
                onChange={handleChange}
                type="date"
              />
              <FormControlFeedback errors={validationErrors} field="date" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="partner_payment.to">
              <Form.Label>To</Form.Label>
              <Form.Control
                value={stats.partner_payment.to}
                onChange={handleChange}
                type="date"
              />
              <FormControlFeedback errors={validationErrors} field="date" />
            </Form.Group>
            <Form.Group className="col-md-4 col-12" controlId="partner_payment.partner_id">
              <Form.Label>Partner</Form.Label>
              <Form.Select
                value={stats.partner_payment.partner_id}
                onChange={handleChange}
              >
                <option value="">Select a partner</option>
                {partners.map((value) => (
                  <option
                    key={value.Id}
                    value={value.Id}
                  >
                    {value.name}
                  </option>
                ))}
              </Form.Select>
              <FormControlFeedback errors={validationErrors} field="partners" />
            </Form.Group>
            <div className="col-12 mt-3 align-self-end">
              <Button onClick={handlePartnerPaymentSubmit} size="lg" disabled={loading}>Search</Button>
            </div>
          </Form>

          {partnerPaymentStats.currency
          && (
            <>
              <div className="mt-3">
                <h3>Summary</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Balance</th>
                      <th>Amount of Transfers</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{`${thousandSeparator(partnerPaymentStats.balance)} ${partnerPaymentStats.currency}`}</td>
                      <td>{partnerPaymentStats.amount_of_transfers}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="mt-3">
                <h3>Trips</h3>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Date & Time</th>
                      <th>Price</th>
                      <th>Cash Received</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Pax</th>

                    </tr>
                  </thead>
                  <tbody>
                    {partnerPaymentStats.trips.map((trip) => (
                      <tr>
                        <td>{trip.booking_reference}</td>
                        <td>{trip.name}</td>
                        <td>
                          {trip.category}
                        </td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                          {`${formatDate(trip.date_time)} ${formatTime(trip.date_time)}`}
                        </td>
                        <td>
                          {trip.price}
                          {' '}
                          {trip.currency}
                        </td>
                        <td>
                          {trip.cash_received}
                          {' '}
                          {trip.cash_received_currency}
                        </td>
                        <td>
                          {trip.from}
                        </td>
                        <td>
                          {trip.to}
                        </td>
                        <td>
                          {trip.pax}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>

          )}
        </div>

      </div>

    </>
  );
}

export default Stats;

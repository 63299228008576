import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { get, post } from '../../Services/API';
import Error from '../../Components/Error';
import format from '../../Utils/dateFormat';

function AcknowledgeNotification() {
  const { bookingId, operatorId } = useParams();
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const [acknowledged, setAcknowledged] = useState(false);
  const shadowRootRef = useRef(null); // Ref for the container where the shadow DOM will be attached

  const acknowledgeNotification = async () => {
    try {
      await post('operators/notifications', { bookingId, operatorId });
      setAcknowledged(true);
    } catch (err) {
      setError('Something went wrong, try again');
    }
  };

  useEffect(() => {
    const loadNotification = async () => {
      try {
        const response = await get('operators/notifications', { bookingId, operatorId });
        if (
          response.data?.data?.notifications
        && Array.isArray(response.data.data.notifications)
        && response.data.data.notifications.length === 1
        ) {
          setNotification(response.data.data.notifications[0]);
        } else {
          setError('No notification found');
        }
      } catch (err) {
        setError('Something went wrong, try again');
      }
    };

    loadNotification();
  }, [bookingId, operatorId]);

  useEffect(() => {
    if (shadowRootRef.current && !shadowRootRef.current.shadowRoot) {
      shadowRootRef.current.attachShadow({ mode: 'open' });
    }

    // Update the content of the shadow DOM when notification changes
    if (notification && shadowRootRef.current?.shadowRoot) {
      shadowRootRef.current.shadowRoot.innerHTML = `<div>${notification.notification}</div>`;
    }

    acknowledgeNotification();
  }, [notification]);

  return (
    <>
      <h1>Notification</h1>
      {error && <Error error={error} setError={setError} />}
      {notification && (
        <>
          {acknowledged && (
          <div className="alert text-bg-success">
            Notification acknowledged, thank you!
          </div>
          )}
          <p>
            Date Sent:
            { ' ' }
            <b className="text-decoration-underline">{format(notification.Date)}</b>
          </p>
          <div className="card">
            <div className="card-body" ref={shadowRootRef} />
          </div>
        </>
      )}
    </>
  );
}

export default AcknowledgeNotification;

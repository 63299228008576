import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

function PieChart({ data }) {
  const slicedData = data.slice(0, 15);

  const chartData = {
    labels: slicedData.map((item) => item.name),
    datasets: [
      {
        data: slicedData.map((item) => item.value),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF',
          '#FF9F40',
          '#E57373',
          '#81C784',
          '#64B5F6',
          '#BA68C8',
          '#FFD54F',
          '#4DD0E1',
          '#F06292',
          '#7986CB',
          '#A1887F',
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  return (
    <Pie data={chartData} options={options} />
  );
}

export default PieChart;

import React, { useState } from 'react';
import { confirmForgotPassword } from '../../../Controllers/AuthController';

// eslint-disable-next-line no-unused-vars
export default function IHaveReceivedACode({ setError, setSuccess, setIHaveReceivedACode }) {
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [loading, setLoading] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const resp = await confirmForgotPassword(email, password, code);

      if (resp === true) {
        setSuccess('Code accepted! You can now log-in with your new password...');
        setLoading(false);
        setIHaveReceivedACode(false);
        return true;
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }

    return false;
  };

  return (
    <>
      <h2>Please enter the code</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="code" className="form-label">Verification Code</label>
          <input
            type="number"
            className="form-control"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="code" className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            id="code"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="btn btn-primary w-100"
        >
          Set new password
        </button>

      </form>
    </>

  );
}

import React from 'react';
import { Alert } from 'react-bootstrap';

function Success({ success, setSuccess }) {
  return (
    success && (
    <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
      <Alert.Heading>OK</Alert.Heading>
      <p>{success}</p>
    </Alert>
    )
  );
}

export default Success;
